import React, { useEffect, useRef, useState } from "react";
import "../styles/style.css";
import drainage from "../images/drainage.png";
import entrance from "../images/Entrance.png";
import exit from "../images/Exit.png";
import noenter from "../images/noentry.png";
import building from "../images/building.png";
import roadcons from "../images/roadcons.png";
import fire from "../images/fire.png";
import vaccident from "../images/vaccident.png";
import flood from "../images/flood.png";
import landslide from "../images/landslide.png";
import axiosphp from "../api/axiosphp";

const markerIcons = {
  "Entrance Only": entrance,
  "Exit Only": exit,
  "No Entry": noenter,
  "Drainage": drainage,
  "Building": building,
  "Road": roadcons,
  "Fire": fire,
  "Flood": flood,
  "Landslide": landslide,
  "Vehicular Accidents": vaccident,
};

const congestionColor = {
  0: "#05FF00",
  1: "#FFE500",
  2: "#FFA800",
  3: "#FF1519",
  4: "#AB0003",
  null: "#FFFFFF",
};

const getCongestionNum = (text) => {
  const mapping = {
    'Freeflow': 0,
    'Light': 1,
    'Moderate': 2,
    'Heavy': 3,
    'Gridlock': 4
  };
  return mapping[text] !== undefined ? mapping[text] : null;
};

const convertTo24HourFormat = (time12h) => {
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier.toUpperCase() === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours.toString().padStart(2, '0')}:${minutes}`;
};

const findCongestionByDateTime = (forecast, selectedDateTime) => {
  return forecast[selectedDateTime] || null;
};

const updateMarkerIcon = (marker, congestionData, camera, selectedDateTime) => {
  const cam_id = camera.cam_id;
  const isTwoLanes = congestionData[cam_id]?.isTwoLanes || false;

  if (isTwoLanes && congestionData[cam_id] && congestionData[cam_id].lanes) {
    const lane1Forecast = congestionData[cam_id].lanes['1']?.forecast || {};
    const lane2Forecast = congestionData[cam_id].lanes['2']?.forecast || {};
    const congestionText1 = findCongestionByDateTime(lane1Forecast, selectedDateTime);
    const congestionText2 = findCongestionByDateTime(lane2Forecast, selectedDateTime);
    const lane1Num = getCongestionNum(congestionText1);
    const lane2Num = getCongestionNum(congestionText2);
    const color1 = congestionColor[lane1Num] || congestionColor.null;
    const color2 = congestionColor[lane2Num] || congestionColor.null;
    if (lane1Num === null && lane2Num === null) {
      if (markerIcons[camera.cameraType]) {
        marker.setIcon({
          url: markerIcons[camera.cameraType],
          scaledSize: new window.google.maps.Size(16, 16),
        });
      } else {
        marker.setIcon({
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: "white",
          fillOpacity: 1,
          scale: 6,
          strokeColor: "#000",
          strokeWeight: 1.5,
        });
      }
      return;
    }

    const canvas = document.createElement('canvas');
    canvas.width = 30;
    canvas.height = 30;
    const context = canvas.getContext('2d');
    context.beginPath();
    context.arc(15, 15, 12, 0.5 * Math.PI, 1.5 * Math.PI);
    context.closePath();
    context.fillStyle = color1;
    context.fill();
    context.beginPath();
    context.arc(15, 15, 12, 1.5 * Math.PI, 0.5 * Math.PI);
    context.closePath();
    context.fillStyle = color2;
    context.fill();
    context.beginPath();
    context.arc(15, 15, 12, 0, 2 * Math.PI);
    context.closePath();
    context.lineWidth = 3;
    context.strokeStyle = '#000';
    context.stroke();
    const iconUrl = canvas.toDataURL();
    marker.setIcon({
      url: iconUrl,
      scaledSize: new window.google.maps.Size(16, 16),
    });
    marker.addListener('click', () => {
      let contentString = `<div><h3>${camera.cam_location}</h3>`;
      contentString += `<p>Lane 1: ${congestionText1 || 'No Data'}</p>`;
      contentString += `<p>Lane 2: ${congestionText2 || 'No Data'}</p>`;
      contentString += `</div>`;
      const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
      });
      infowindow.open(marker.getMap(), marker);
    });
  } else if (!isTwoLanes && congestionData[cam_id] && congestionData[cam_id].lanes && congestionData[cam_id].lanes['1']) {
    const congestionText = findCongestionByDateTime(congestionData[cam_id].lanes['1'].forecast, selectedDateTime);
    const congestionNum = getCongestionNum(congestionText);
    const backgroundColor = congestionColor[congestionNum] || congestionColor.null;
    if (congestionNum === null) {
      if (markerIcons[camera.cameraType]) {
        marker.setIcon({
          url: markerIcons[camera.cameraType],
          scaledSize: new window.google.maps.Size(16, 16),
        });
      } else {
        marker.setIcon({
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: "white",
          fillOpacity: 1,
          scale: 6,
          strokeColor: "#000",
          strokeWeight: 1.5,
        });
      }
      return;
    }

    const customMarker = {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor: backgroundColor,
      fillOpacity: 1,
      scale: 6.5,
      strokeColor: "#000",
      strokeWeight: 1.6,
    };

    marker.setIcon(customMarker);
    marker.addListener('click', () => {
      let contentString = `<div><h3>${camera.cam_location}</h3>`;
      contentString += `<p>Single Lane: ${congestionText || 'No Data'}</p>`;
      contentString += `</div>`;
      const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
      });
      infowindow.open(marker.getMap(), marker);
    });
  } else {
    if (markerIcons[camera.cameraType]) {
      marker.setIcon({
        url: markerIcons[camera.cameraType],
        scaledSize: new window.google.maps.Size(16, 16),
      });
    } else {
      marker.setIcon({
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: "white",
        fillOpacity: 1,
        scale: 6,
        strokeColor: "#000",
        strokeWeight: 1.5,
      });
    }
    marker.addListener('click', () => {
      const contentString = `<div><h3>${camera.cam_location || "Traffic Camera"}</h3><p>No congestion data available.</p></div>`;
      const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
      });
      infowindow.open(marker.getMap(), marker);
    });
  }

  marker.setTitle(camera.cam_location || "No Location Info");
};

function MapComponentForecast({ initialDrawings, selectedDate, selectedTime }) {
  const mapRef = useRef(null);
  const [cameraData, setCameraData] = useState([]);
  const [allCongestionData, setAllCongestionData] = useState({});
  const markersRef = useRef([]);

  const fetchAllData = async () => {
    try {
      const response = await axiosphp.get("/fetch_sarimax_result.php");
      console.log("All Congestion Data Response:", response.data);
      if (response.data.success && response.data.data) {
        setAllCongestionData(response.data.data);
      } else {
        console.error("Error fetching congestion data:", response.data.message);
        setAllCongestionData({});
      }

      const cameraResponse = await axiosphp.get("/fetch_traffic_cameras.php");
      console.log("Camera Data Response:", cameraResponse.data);
      setCameraData(cameraResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setAllCongestionData({});
      setCameraData([]);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const loadMap = () => {
    if (!window.google || !window.google.maps) {
      console.error("Google Maps JavaScript API not loaded.");
      return;
    }

    const map = new window.google.maps.Map(document.getElementById("mapstyle"), {
      center: { lat: 11.2416, lng: 125.0028 },
      zoom: 13,
    });

    mapRef.current = map;

    if (initialDrawings && initialDrawings.length > 0) {
      initialDrawings.forEach((data) => {
        let overlay;
        switch (data.type) {
          case window.google.maps.drawing.OverlayType.MARKER:
            overlay = new window.google.maps.Marker({
              position: data.position,
              map: map,
              icon: {
                url: markerIcons[data.name] || null,
                scaledSize: new window.google.maps.Size(50, 30),
              },
              label: data.name,
            });
            break;
          case window.google.maps.drawing.OverlayType.CIRCLE:
            overlay = new window.google.maps.Circle({
              center: data.position,
              radius: data.size,
              map: map,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          case window.google.maps.drawing.OverlayType.POLYGON:
            overlay = new window.google.maps.Polygon({
              paths: data.position,
              map: map,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          case window.google.maps.drawing.OverlayType.POLYLINE:
            overlay = new window.google.maps.Polyline({
              path: data.position,
              map: map,
              strokeColor: data.color,
              strokeWeight: 4,
            });
            break;
          case window.google.maps.drawing.OverlayType.RECTANGLE:
            overlay = new window.google.maps.Rectangle({
              bounds: data.position,
              map: map,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          default:
            console.warn(`Unsupported overlay type: ${data.type}`);
            return;
        }
      });
    }

    if (cameraData && cameraData.length > 0) {
      cameraData.forEach(camera => {
        const { cam_id, cam_latitude, cam_longitude, cam_location, type } = camera;

        if (cam_latitude !== undefined && cam_longitude !== undefined) {
          const congestionInfo = allCongestionData[cam_id];
          const isTwoLanes = congestionInfo?.isTwoLanes || false;

          const marker = new window.google.maps.Marker({
            position: { lat: parseFloat(cam_latitude), lng: parseFloat(cam_longitude) },
            map: map,
            title: cam_location || "Traffic Camera",
            cameraType: type,
            cam_id: cam_id,
            isTwoLanes: isTwoLanes,
          });

          const time24h = convertTo24HourFormat(selectedTime);
          const selectedDateTime = `${selectedDate} ${time24h}:00`;
          updateMarkerIcon(marker, allCongestionData, camera, selectedDateTime);

          markersRef.current.push(marker);
        }
      });
    }
  };

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.drawing) {
      loadMap();
    } else {
      console.error("Google Maps JavaScript API or Drawing library not loaded.");
    }

    return () => {
      markersRef.current.forEach(marker => marker.setMap(null));
      markersRef.current = [];
    };
  }, [initialDrawings, cameraData, allCongestionData]);

  useEffect(() => {
    if (!mapRef.current) return;
    const map = mapRef.current;
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];
    if (cameraData && cameraData.length > 0) {
      cameraData.forEach(camera => {
        const { cam_id, cam_latitude, cam_longitude, cam_location, type } = camera;
        if (cam_latitude !== undefined && cam_longitude !== undefined) {
          const congestionInfo = allCongestionData[cam_id];
          const isTwoLanes = congestionInfo?.isTwoLanes || false;
          const marker = new window.google.maps.Marker({
            position: { lat: parseFloat(cam_latitude), lng: parseFloat(cam_longitude) },
            map: map,
            title: cam_location || "Traffic Camera",
            cameraType: type,
            cam_id: cam_id,
            isTwoLanes: isTwoLanes,
          });
          const time24h = convertTo24HourFormat(selectedTime);
          const selectedDateTime = `${selectedDate} ${time24h}:00`;
          updateMarkerIcon(marker, allCongestionData, camera, selectedDateTime);
          markersRef.current.push(marker);
        }
      });
    }
  }, [selectedDate, selectedTime, allCongestionData, cameraData]);

  return (
    <div
      id="mapstyle"
      className="mapstyle"
    ></div>
  );
}

export default MapComponentForecast;