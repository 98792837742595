/* global google */
import React, { useEffect, useRef } from "react";
import "../styles/style.css";
import drainage from "../images/drainage.png";
import entrance from "../images/Entrance.png";
import exit from "../images/Exit.png";
import noenter from "../images/noentry.png";
import building from "../images/building.png";
import roadcons from "../images/roadcons.png";
import fire from "../images/fire.png";
import vaccident from "../images/vaccident.png";
import flood from "../images/flood.png";
import landslide from "../images/landslide.png";

const markerIcons = {
  "Entrance Only": entrance,
  "Exit Only": exit,
  "No Entry": noenter,
  "Drainage": drainage,
  "Building": building,
  "Road": roadcons,
  "Fire": fire,
  "Flood": flood,
  "Landslide": landslide,
  "Vehicular Accidents": vaccident,
};

function MapComponentDraw({ markerType, drawColor, eraseMode, overlaysRef, initialDrawings }) {
  const mapRef = useRef(null);
  const drawingManagerRef = useRef(null);
  const initialDrawingsLoadedRef = useRef(false);
  const addEraseListener = (overlay) => {
    const listenerHandle = google.maps.event.addListener(overlay, "click", function () {
      overlay.setMap(null);
      overlaysRef.current = overlaysRef.current.filter(item => item !== overlay);
    });
    overlay.listenerHandle = listenerHandle;
  };

  useEffect(() => {
    const colorOptions = {
      green: "#00FF00",
      red: "#FF0000",
      yellow: "#FFFF00",
      blue: "#0000FF",
    };

    const selectedColor = colorOptions[drawColor] || "#0000FF"; 

    const loadMap = () => {
      if (!window.google || !window.google.maps || !window.google.maps.Marker) {
        console.error("Google Maps JavaScript API or Marker library not loaded.");
        return;
      }

      if (!mapRef.current) {
        const styles = [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }]
          },
        ];
        const map = new window.google.maps.Map(document.getElementById("mapstyle"), {
          center: { lat: 11.2416, lng: 125.0028 },
          zoom: 13,
          styles: styles, 
        });

        mapRef.current = map;
      }

      if (drawingManagerRef.current) {
        drawingManagerRef.current.setMap(null); 
      }

      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ['marker', 'circle', 'polygon', 'polyline', 'rectangle'],
        },
        markerOptions: {
          draggable: true,
        },
        polylineOptions: {
          strokeColor: selectedColor,
          strokeWeight: 4,
        },
        polygonOptions: {
          strokeColor: selectedColor,
          fillColor: selectedColor,
          fillOpacity: 0.35,
          strokeWeight: 3,
        },
        rectangleOptions: {
          strokeColor: selectedColor,
          fillColor: selectedColor,
          fillOpacity: 0.35,
          strokeWeight: 3,
        },
        circleOptions: {
          strokeColor: selectedColor,
          fillColor: selectedColor,
          fillOpacity: 0.35,
          strokeWeight: 3,
        },
      });

      drawingManager.setMap(mapRef.current);
      drawingManagerRef.current = drawingManager;
      window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
        const overlay = event.overlay;
        overlay.metadata = { type: event.type, color: selectedColor };
        switch (event.type) {
          case window.google.maps.drawing.OverlayType.MARKER:
            configureMarker(overlay);
            break;
          case window.google.maps.drawing.OverlayType.CIRCLE:
            configureCircle(overlay);
            break;
          case window.google.maps.drawing.OverlayType.POLYGON:
            configurePolygon(overlay);
            break;
          case window.google.maps.drawing.OverlayType.POLYLINE:
            configurePolyline(overlay);
            break;
          case window.google.maps.drawing.OverlayType.RECTANGLE:
            configureRectangle(overlay);
            break;
          default:
            break;
        }
        overlaysRef.current.push(overlay);
        if (eraseMode) {
          addEraseListener(overlay);
        }
      });
      window.clearMap = () => {
        overlaysRef.current.forEach(overlay => {
          overlay.setMap(null);
        });
        overlaysRef.current = [];
      };
    };

    const configureMarker = (marker) => {
      switch (markerType) {
        case "entrance":
          marker.setIcon({
            url: entrance,
            scaledSize: new google.maps.Size(50, 30) 
          });
          marker.setLabel("Entrance Only");
          break;
        case "exit":
          marker.setIcon({
            url: exit,
            scaledSize: new google.maps.Size(50, 30)
          });
          marker.setLabel("Exit Only");
          break;
        case "no-entry":
          marker.setIcon({
            url: noenter,
            scaledSize: new google.maps.Size(50, 30)
          });
          marker.setLabel("No Entry");
          break;
        case "drainage":
          marker.setIcon({
            url: drainage,
            scaledSize: new google.maps.Size(50, 30) 
          });
          marker.setLabel("Drainage");
          break;
        case "building":
          marker.setIcon({
            url: building,
            scaledSize: new google.maps.Size(50, 30) 
          });
          marker.setLabel("Building");
          break;
        case "road":
          marker.setIcon({
            url: roadcons,
            scaledSize: new google.maps.Size(50, 30) 
          });
          marker.setLabel("Road");
          break;
        case "fire":
          marker.setIcon({
            url: fire,
            scaledSize: new google.maps.Size(50, 30) 
          });
          marker.setLabel("Fire");
          break;
        case "flood":
          marker.setIcon({
            url: flood,
            scaledSize: new google.maps.Size(50, 30)
          });
          marker.setLabel("Flood");
          break;
        case "landslide":
          marker.setIcon({
            url: landslide,
            scaledSize: new google.maps.Size(50, 30)
          });
          marker.setLabel("Landslide");
          break;
        case "vehicular-accidents":
          marker.setIcon({
            url: vaccident,
            scaledSize: new google.maps.Size(50, 30) 
          });
          marker.setLabel("Vehicular Accidents");
          break;
        default:
          break;
      }
    };

    const configureCircle = (circle) => {
      circle.setOptions({
        strokeColor: selectedColor,
        fillColor: selectedColor,
        fillOpacity: 0.35,
        strokeWeight: 3,
      });
    };

    const configurePolygon = (polygon) => {
      polygon.setOptions({
        strokeColor: selectedColor,
        fillColor: selectedColor,
        fillOpacity: 0.35,
        strokeWeight: 3,
      });
    };

    const configurePolyline = (polyline) => {
      polyline.setOptions({
        strokeColor: selectedColor,
        strokeWeight: 4,
      });
    };

    const configureRectangle = (rectangle) => {
      rectangle.setOptions({
        strokeColor: selectedColor,
        fillColor: selectedColor,
        fillOpacity: 0.35,
        strokeWeight: 3,
      });
    };

    if (!window.google || !window.google.maps || !window.google.maps.drawing) {
      console.error("Google Maps JavaScript API or Drawing library not loaded.");
      return;
    }
    loadMap();
    if (!initialDrawingsLoadedRef.current && initialDrawings && initialDrawings.length > 0) {
      initialDrawings.forEach((data) => {
        let overlay;

        switch (data.type) {
          case window.google.maps.drawing.OverlayType.MARKER:
            overlay = new window.google.maps.Marker({
              position: data.position,
              map: mapRef.current,
              icon: {
                url: markerIcons[data.name] || null,
                scaledSize: new window.google.maps.Size(50, 30),
              },
              label: data.name,
            });
            break;
          case window.google.maps.drawing.OverlayType.CIRCLE:
            overlay = new window.google.maps.Circle({
              center: data.position,
              radius: data.size,
              map: mapRef.current,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          case window.google.maps.drawing.OverlayType.POLYGON:
            overlay = new window.google.maps.Polygon({
              paths: data.position,
              map: mapRef.current,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          case window.google.maps.drawing.OverlayType.POLYLINE:
            overlay = new window.google.maps.Polyline({
              path: data.position,
              map: mapRef.current,
              strokeColor: data.color,
              strokeWeight: 4,
            });
            break;
          case window.google.maps.drawing.OverlayType.RECTANGLE:
            overlay = new window.google.maps.Rectangle({
              bounds: data.position,
              map: mapRef.current,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          default:
            console.warn(`Unsupported overlay type: ${data.type}`);
            return;
        }
        overlay.metadata = {
          type: data.type,
          color: data.color,
        };
        overlaysRef.current.push(overlay);
        if (eraseMode) {
          addEraseListener(overlay);
        }
      });
      initialDrawingsLoadedRef.current = true;
    }
  }, [markerType, drawColor, eraseMode, initialDrawings, overlaysRef]);

  useEffect(() => {
    overlaysRef.current.forEach(overlay => {
      if (eraseMode) {
        addEraseListener(overlay);
      } else {
        if (overlay.listenerHandle) {
          google.maps.event.removeListener(overlay.listenerHandle);
          delete overlay.listenerHandle;
        }
      }
    });

    return () => {
      overlaysRef.current.forEach(overlay => {
        if (overlay.listenerHandle) {
          google.maps.event.removeListener(overlay.listenerHandle);
          delete overlay.listenerHandle;
        }
      });
    };
  }, [eraseMode]);
  return <div id="mapstyle" className="mapstyle"></div>;
}
export default MapComponentDraw;