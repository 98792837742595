import React, { useState, useEffect, useContext } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import "../styles/style.css";
import { useNavigate } from "react-router-dom";
import axiosphp from "../api/axiosphp";
import axiosFlask from "../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../context/UserContext";
import ImageViewer from 'react-simple-image-viewer';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  MdLogout,
  MdBlock,
  MdDownload,
  MdLeaderboard,
  MdArrowBackIosNew,
  MdArrowForwardIos,
} from 'react-icons/md';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// Mapping for congestion levels
const congestionLevelNumericMap = {
  "Freeflow": 0,
  "Light": 1,
  "Moderate": 2,
  "Heavy": 3,
  "Gridlock": 4,
};

const congestionLevelLabelMap = {
  0: "Freeflow",
  1: "Light",
  2: "Moderate",
  3: "Heavy",
  4: "Gridlock",
};

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const dataPoint = payload[0].payload;
    const congestionLevel = dataPoint.congestion_level || "N/A";
    const congestionColors = {
      Gridlock: "#AB0003",
      Heavy: "#FF1519",
      Moderate: "#FFA800",
      Light: "#FFE500",
      Freeflow: "#05FF00",
    };
    const congestionColor = congestionColors[congestionLevel] || "#000";

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "white",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p>
          <strong>Date:</strong> {dataPoint.date_time}
        </p>
        <p>
          <strong>Congestion:</strong>{" "}
          <span style={{ color: congestionColor }}>{congestionLevel}</span>
        </p>
      </div>
    );
  }

  return null;
};

// Custom Dot Component to Color Based on Congestion Level
const CustomDot = (props) => {
  const { cx, cy, payload } = props;
  const congestionLevel = payload.congestion_level || "Unknown";
  const congestionColors = {
    Freeflow: "#05FF00", // Green
    Light: "#FFE500",     // Yellow
    Moderate: "#FFA800",  // Orange
    Heavy: "#FF1519",     // Red
    Gridlock: "#AB0003",  // Purple
  };

  return (
    <circle
      cx={cx}
      cy={cy}
      r={5}
      fill={congestionColors[congestionLevel] || "#8884d8"}
      stroke="none"
    />
  );
};

// Custom Legend Component
const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
      {payload.map((entry, index) => (
        <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <div
            style={{
              width: '10px',
              height: '10px',
              backgroundColor: entry.color,
              marginRight: '5px'
            }}
          ></div>
          <span>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

function CommandCivillian() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { setUser } = useContext(UserContext);

  const [imageUrls, setImageUrls] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [forecastData, setForecastData] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const [currentCameraIndex, setCurrentCameraIndex] = useState(0); // Current camera index
  const [predictionPeriod, setPredictionPeriod] = useState('48_hours'); // Prediction period state

  const handleDownloadForecast = () => {
    axiosFlask.get('/download_forecast', {
      responseType: 'blob'
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sarimaxresult.zip');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error downloading forecast:', error);
        toast.error('Failed to download forecast.', { autoClose: 3000 });
      });
  };

  const ShowSarimax = async () => {
    try {
      const response = await axiosFlask.get('/sarimax_images');
      const imageFilenames = response.data.images;
      const urls = imageFilenames.map((filename) => `https://api.intellitraffic.info/flask/sarimax_images/${filename}`);
      setImageUrls(urls);
      setIsViewerOpen(true);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const showPrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length);
  };

  const [newUser, setNewUser] = useState({
    firstname: "",
    lastname: "",
    username: "",
    password: ""
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const goc = (event) => {
    event.preventDefault();
    navigate("/commandc");
  };

  const gote = (event) => {
    event.preventDefault();
    navigate("/commandte");
  };
  const gota = (event) => {
    event.preventDefault();
    navigate("/commandta");
  };

  const goa = (event) => {
    event.preventDefault();
    navigate("/commanda");
  };

  const goh = (event) => {
    event.preventDefault();
    navigate("/commandh");
  };

  const gomc = (event) => {
    event.preventDefault();
    navigate("/commandmc");
  };

  const logout = (event) => {
    setUser(null);
    event.preventDefault();
    navigate("/login");
  };

  useEffect(() => {
    axiosphp.get('/fetch_accounts_data.php')
      .then(response => {
        setData(response.data.users);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  const handleInputChange = (event) => {
    setNewUser({
      ...newUser,
      [event.target.name]: event.target.value
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const newUserWithType = { ...newUser, type: 'normal' };
    axiosphp.post('/account_insert.php', newUserWithType)
      .then(response => {
        if (response.data.success) {
          setData([...data, response.data.user]);
          setShowModal(false);
          setNewUser({ firstname: "", lastname: "", username: "", password: "" });
          toast.success("User inserted successfully!", { autoClose: 3000 });
        } else {
          console.error('There was an error!', response.data.message);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  const handleRowClick = (user, index) => {
    setSelectedUser(user);
    setSelectedRowIndex(index);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    axiosphp.put(`/account_update.php?id=${selectedUser.id}`, selectedUser)
      .then(response => {
        if (response.data.success) {
          setData(data.map(user => user.id === selectedUser.id ? response.data.user : user));
          setSelectedUser(null);
          setShowEditModal(false);
          toast.success("User updated successfully!", { autoClose: 3000 });
        } else {
          console.error('There was an error!', response.data.message);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  //EDIT DISABLED
  // const handleEditButtonClick = () => {
  //   if (selectedUser) {
  //     setShowEditModal(true);
  //   } else {
  //     toast.info("Please select user to EDIT", { autoClose: 3000 });
  //   }
  // };

  const handleDeleteButtonClick = () => {
    if (selectedUser) {
      setShowDeleteModal(true);
    } else {
      toast.info("Please select user to DELETE", { autoClose: 3000 });
    }
  };

  const handleDeleteConfirm = () => {
    const type = 'normal';
    axiosphp.delete(`/account_delete.php?id=${selectedUser.id}&type=${type}`)
      .then(response => {
        if (response.data.success) {
          setData(data.filter(user => user.id !== selectedUser.id));
          setSelectedUser(null);
          setShowDeleteModal(false);
          toast.success("User deleted successfully!", { autoClose: 3000 });
        } else {
          console.error('There was an error!', response.data.message);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  const loadForecastData = async (period = '48_hours') => {
    try {
      const response = await axiosFlask.get('/sarimax/forecasts.json');
      // Process the data to include congestion_level_num and limit to the selected period
      const processedData = {};
      const currentTime = new Date();
      let futureTime;

      if (period === '48_hours') {
        futureTime = new Date(currentTime.getTime() + 48 * 60 * 60 * 1000);
      } else if (period === '2_months') {
        futureTime = new Date(currentTime.getTime() + 60 * 24 * 60 * 60 * 1000); // Approximately 2 months
      }

      Object.keys(response.data).forEach(camera => {
        processedData[camera] = response.data[camera]
          .filter(dataPoint => {
            const pointTime = new Date(dataPoint.date_time);
            return pointTime >= currentTime && pointTime <= futureTime;
          })
          .map(dataPoint => ({
            ...dataPoint,
            congestion_level_num: congestionLevelNumericMap[dataPoint.congestion_level] !== undefined
              ? congestionLevelNumericMap[dataPoint.congestion_level]
              : -1, // Assign -1 for unknown levels
          }));
      });

      setForecastData(processedData);
      setShowChart(true);
      setIsViewerOpen(false); // Close image viewer when showing chart

      // Ensure currentCameraIndex is within bounds
      const totalCameras = Object.keys(processedData).length;
      if (currentCameraIndex >= totalCameras) {
        setCurrentCameraIndex(totalCameras - 1);
      }

      setPredictionPeriod(period); // Update prediction period state
    } catch (error) {
      console.error('Error fetching forecast data:', error);
      toast.error('Failed to load forecast data.', { autoClose: 3000 });
    }
  };

  // Navigate to next camera
  const handleNextChart = () => {
    if (forecastData) {
      const cameraNames = Object.keys(forecastData);
      setCurrentCameraIndex((prevIndex) => (prevIndex + 1) % cameraNames.length);
    }
  };

  // Navigate to previous camera
  const handlePrevChart = () => {
    if (forecastData) {
      const cameraNames = Object.keys(forecastData);
      setCurrentCameraIndex((prevIndex) => (prevIndex - 1 + cameraNames.length) % cameraNames.length);
    }
  };

  return (
    <div className="custom-container">
      <ToastContainer closeButton={false} />

      {/* Add User Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" name="firstname" value={newUser.firstname} onChange={handleInputChange} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" name="lastname" value={newUser.lastname} onChange={handleInputChange} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" name="username" value={newUser.username} onChange={handleInputChange} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" value={newUser.password} onChange={handleInputChange} required />
            </Form.Group>
            <Button type="submit" className="mt-3">Add Account</Button>
            <Button variant="secondary" onClick={() => setShowModal(false)} className="mt-3 ms-2">Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Edit User Modal */}
      <Modal show={showEditModal} onHide={() => { setShowEditModal(false); setSelectedUser(null); }}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditFormSubmit}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" name="firstname" value={selectedUser ? selectedUser.firstname : ''} onChange={(e) => setSelectedUser({ ...selectedUser, firstname: e.target.value })} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" name="lastname" value={selectedUser ? selectedUser.lastname : ''} onChange={(e) => setSelectedUser({ ...selectedUser, lastname: e.target.value })} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" name="username" value={selectedUser ? selectedUser.username : ''} onChange={(e) => setSelectedUser({ ...selectedUser, username: e.target.value })} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" value={selectedUser ? selectedUser.password : ''} onChange={(e) => setSelectedUser({ ...selectedUser, password: e.target.value })} required />
            </Form.Group>
            <Button type="submit" className="mt-3">Edit Account</Button>
            <Button variant="secondary" onClick={() => { setSelectedUser(null); setShowEditModal(false); }} className="mt-3 ms-2">Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete User Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this account?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeleteConfirm}>Delete Account</Button>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <div className="info-hc">
        <p><span className="highlight">Civilian </span> Account Management</p>
      </div>

      <div className="table-and-buttons">
        <Table className="table-dc" responsive>
          <thead className="theaderc">
            <tr>
              <th>ID</th>
              <th>Firstname</th>
              <th>Lastname</th>
              <th>Username</th>
              <th>Password</th>
            </tr>
          </thead>
          <tbody className="tablebodyc">
            {data.map((row, index) => (
              <tr key={index} onClick={() => handleRowClick(row, index)} className={index === selectedRowIndex ? 'selected' : ''}>
                <td>{row.id}</td>
                <td>{row.firstname}</td>
                <td>{row.lastname}</td>
                <td>{row.username}</td>
                <td>{'****'}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="5">
                <div className="func-container">
                  <Button className="func-button" onClick={() => setShowModal(true)}>
                    Add
                  </Button>
                  {/* <Button className="func-button" disabled onClick={handleEditButtonClick}>
                    Edit
                  </Button> */}
                  <Button className="func-button" onClick={handleDeleteButtonClick}>
                    Delete
                  </Button>
                  <Button className="func-buttongreen func-button" onClick={ShowSarimax}>
                    Sarimax Performance
                  </Button>
                  <Button onClick={logout} className="current-func">
                    Logout
                  </Button>
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>

        <div className="info-datac">
          <div className="his-button">
            <Button onClick={goc} className="all-buttonc current-btnc h-buttonc">
              Civilian
            </Button>
            <Button onClick={gote} className="all-buttonc h-buttonc">
              Traffic Enforcer
            </Button>
            <Button onClick={gota} className="all-buttonc h-buttonc">
              Traffic Agency
            </Button>
            <Button onClick={goa} className="all-buttonc h-buttonc">
              Admin
            </Button>
            <Button onClick={goh} className="all-buttonc h-buttonc">
              Traffic History
            </Button>
            <Button onClick={gomc} className="all-buttonc h-buttonc">
              Multiple Camera
            </Button>
          </div>
        </div>
      </div>

      {/* Image Viewer */}
      {isViewerOpen && imageUrls.length > 0 && (
        <div className="image-viewer-overlay">
          <div className="image-viewer-container">
            {/* Image Viewer Buttons */}
            <button className="image-viewer-close" onClick={closeImageViewer}>
              <MdBlock />
            </button>
            <button className="image-viewer-dl" onClick={handleDownloadForecast}>
              <MdDownload />
            </button>
            <button className="image-viewer-an" onClick={() => loadForecastData(predictionPeriod)}>
              <MdLeaderboard />
            </button>
            {imageUrls.length > 1 && (
              <>
                <button className="image-viewer-prev" onClick={showPrevImage}>
                  <MdArrowBackIosNew />
                </button>
                <button className="image-viewer-next" onClick={showNextImage}>
                  <MdArrowForwardIos />
                </button>
              </>
            )}
            {/* Image with Zoom/Pan Functionality */}
            <TransformWrapper
              defaultScale={1}
              wheel={{ step: 0.1 }}
            >
              <TransformComponent>
                <img
                  src={imageUrls[currentImageIndex]}
                  alt="Sarimax Result"
                  className="image-viewer-image"
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      )}

      {/* Chart Viewer Overlay */}
      {showChart && forecastData && (
        <div className="chart-overlay">
          <button className="chart-close" onClick={() => setShowChart(false)}>
            <MdBlock />
          </button>
          <div className="chart-navigation">
            <Button onClick={handlePrevChart} disabled={currentCameraIndex === 0} style={{ color: 'black' }}>
              Previous
            </Button>
            <Button onClick={handleNextChart} disabled={currentCameraIndex === Object.keys(forecastData).length - 1} style={{ color: 'black' }}>
              Next
            </Button>
            <Button
              onClick={() => loadForecastData('48_hours')}
              className={`ms-2 ${predictionPeriod === '48_hours' ? 'selectedblue' : 'outline-secondary'}`}
              style={{ color: 'black' }}
            >
              48 Hours Prediction
            </Button>
            <Button
              onClick={() => loadForecastData('2_months')}
              className={`ms-2 ${predictionPeriod === '2_months' ? 'selectedblue' : 'outline-secondary'}`}
              style={{ color: 'black' }}
            >
              Two Months Prediction
            </Button>
          </div>
          <div className="chart-container">
            {Object.keys(forecastData).length > 0 && (
              <div className="chart-wrapper">
                <h3>{Object.keys(forecastData)[currentCameraIndex]}</h3>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart
                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                    data={forecastData[Object.keys(forecastData)[currentCameraIndex]]}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date_time" />
                    <YAxis
                      type="number"
                      domain={[0, 4]}
                      ticks={[0, 1, 2, 3, 4]}
                      tickFormatter={(tick) => congestionLevelLabelMap[tick]}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={<CustomLegend />} />
                    <Line
                      type="monotone"
                      dataKey="congestion_level_num"
                      name="Congestion Level"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                      dot={(props) => <CustomDot {...props} />}
                      connectNulls
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CommandCivillian;