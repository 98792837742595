// MapForecast.js
import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MapComponent from "../components/MapComponentForecast";
import "../styles/style.css";
import { UserContext } from "../context/UserContext";
import axiosphp from "../api/axiosphp";

function MapForecast() {
  const navigate = useNavigate();
  const [drawingsData, setDrawingsData] = useState([]);
  const { setUser } = useContext(UserContext);
  const now = new Date();
  now.setMinutes(0, 0, 0); 
  const [selectedDate, setSelectedDate] = useState(now.toISOString().slice(0, 10)); 
  const hours = now.getHours();
  const isPM = hours >= 12;
  const twelveHour = hours % 12 || 12;
  const ampm = isPM ? 'PM' : 'AM';
  const initialTime = `${twelveHour}:00 ${ampm}`;
  const [selectedTime, setSelectedTime] = useState(initialTime);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    const fetchDrawingsData = async () => {
      try {
        const response = await axiosphp.get("/drawfetch.php");
        console.log("Drawings Data Response:", response.data);
        if (response.data.success && response.data.drawingsData) {
          setDrawingsData(response.data.drawingsData);
        } else {
          console.error("Error fetching drawings data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching drawings data:", error);
      }
    };

    fetchDrawingsData();
    const fetchForecastData = async () => {
      try {
        const response = await axiosphp.get("/fetch_sarimax_result.php");
        console.log("Forecast Data Response:", response.data);
        if (response.data.success && response.data.data) {
          let maxForecastDate = null;
          const data = response.data.data;
          Object.values(data).forEach(camData => {
            if (camData.lanes) {
              Object.values(camData.lanes).forEach(laneData => {
                if (laneData.forecast) {
                  const dates = Object.keys(laneData.forecast);
                  dates.forEach(dateStr => {
                    const date = new Date(dateStr);
                    if (!isNaN(date) && (maxForecastDate === null || date > maxForecastDate)) {
                      maxForecastDate = date;
                    }
                  });
                }
              });
            }
          });

          if (maxForecastDate) {
            setMaxDate(maxForecastDate);
          } else {
            console.error("No valid forecast dates found.");
          }
        } else {
          console.error("Error fetching forecast data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching forecast data:", error);
      }
    };

    fetchForecastData();
  }, []);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const golinterface = (event) => {
    event.preventDefault();
    navigate("/mapdraw");
  };

  const gomap = (event) => {
    event.preventDefault();
    navigate("/map");
  };

  const logout = (event) => {
    setUser(null);
    event.preventDefault();
    navigate("/login");
  };

  return (
    <div className="custom-container">
      <div className="info-map">
        <p>
          <span className="highlight">Map </span>Forecast
        </p>
      </div>
      <div className="map-body">
        <div className="map">
          <MapComponent
            initialDrawings={drawingsData}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
          />
        </div>
        <div className="info-data">
          <div className="maps-button">
            <div className="time-selector">
              <label htmlFor="datePicker">Select Date:</label>
              <input
                type="date"
                id="datePicker"
                name="datePicker"
                value={selectedDate}
                onChange={handleDateChange}
                min={now.toISOString().slice(0, 10)}
                max={maxDate ? maxDate.toISOString().slice(0, 10) : ''}
              />
            </div>
            <div className="time-selector">
              <label htmlFor="timePicker">Select Time:</label>
              <select
                id="timePicker"
                name="timePicker"
                value={selectedTime}
                onChange={handleTimeChange}
              >
                {Array.from({ length: 24 }, (_, i) => {
                  const hour = i % 12 || 12;
                  const ampm = i < 12 ? 'AM' : 'PM';
                  const timeString = `${hour}:00 ${ampm}`;
                  return (
                    <option key={i} value={timeString}>
                      {timeString}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="congestion-info">
              <p className="conlevel-forecast">
                GRIDLOCK <span className="circle gridlock"></span>
              </p>
              <p className="conlevel-forecast">
                HEAVY <span className="circle heavy"></span>
              </p>
              <p className="conlevel-forecast">
                MODERATE <span className="circle moderate"></span>
              </p>
              <p className="conlevel-forecast">
                LIGHT <span className="circle low"></span>
              </p>
              <p className="conlevel-forecast">
                FREE FLOW <span className="circle free-flow"></span>
              </p>
            </div>
            <div className="twobuttons">
              <Button onClick={gomap} className="map-buttons">
                Map System
              </Button>
              <Button onClick={golinterface} className="map-buttons">
                Map Reroute
              </Button>
              <Button onClick={logout} className="map-buttons">
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MapForecast;