/* global google */
import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, FormControl, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MapComponentDraw from "../components/MapComponentDraw";
import { ToastContainer, toast } from "react-toastify";
import "../styles/style.css";

function MapDrawing() {
  const navigate = useNavigate();
  const [markerType, setMarkerType] = useState("entrance");
  const [activeButton, setActiveButton] = useState("entrance");
  const [drawColor, setDrawColor] = useState("blue");
  const [activeColorButton, setActiveColorButton] = useState("blue");
  const [eraseMode, setEraseMode] = useState(false);
  const [drawingsData, setDrawingsData] = useState([]);
  const overlaysRef = useRef([]);
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [announcementTitle, setAnnouncementTitle] = useState("");
  const [announcementText, setAnnouncementText] = useState("");
  const [announcements, setAnnouncements] = useState([]);
  const [filteredAnnouncements, setFilteredAnnouncements] = useState([]);
  const [showAnnouncementlist, setShownnouncementlist] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [drawingName, setDrawingName] = useState('');
  const [savedDrawings, setSavedDrawings] = useState([]);
  const [selectedDrawing, setSelectedDrawing] = useState(null);

  useEffect(() => {
    fetch("https://api.intellitraffic.info/php/drawfetch.php")
      .then(response => response.json())
      .then(data => {
        if (data.success && data.drawingsData) {
          setDrawingsData(data.drawingsData);
        } else {
          console.error("Error fetching data:", data.message);
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  }, []);

  const fetchSavedDrawings = () => {
    fetch("https://api.intellitraffic.info/php/drawfetch_saved.php")
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setSavedDrawings(data.drawings);
        } else {
          console.error("Error fetching saved drawings:", data.message);
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  const map = (event) => {
    event.preventDefault();
    navigate("/map");
  };

  const handleButtonClick = (type) => {
    setMarkerType(type);
    setActiveButton(type);
  };

  const handleColorButtonClick = (color) => {
    setDrawColor(color);
    setActiveColorButton(color);
  };

  const handleClear = () => {
    window.clearMap();
  };

  const handleErase = () => {
    setEraseMode((prev) => !prev);
  };

  const handleSave = () => {
    const savedData = overlaysRef.current.map((overlay) => {
      let data = {
        type: overlay.metadata.type,
        position: null,
        name: null,
        size: null,
        color: overlay.metadata.color || null,
      };

      switch (overlay.metadata.type) {
        case google.maps.drawing.OverlayType.MARKER:
          data.position = overlay.getPosition().toJSON();
          data.name = overlay.getLabel();
          break;
        case google.maps.drawing.OverlayType.CIRCLE:
          data.position = overlay.getCenter().toJSON();
          data.size = overlay.getRadius();
          break;
        case google.maps.drawing.OverlayType.POLYGON:
        case google.maps.drawing.OverlayType.POLYLINE:
          data.position = overlay.getPath().getArray().map(point => point.toJSON());
          break;
        case google.maps.drawing.OverlayType.RECTANGLE:
          data.position = overlay.getBounds().toJSON();
          break;
        default:
          console.warn(`Unsupported overlay type: ${overlay.metadata.type}`);
          return null;
      }
      return data;
    }).filter(Boolean);

    const jsonData = JSON.stringify({ drawingsData: savedData });
    console.log(jsonData);
    fetch("https://api.intellitraffic.info/php/drawinsert.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: jsonData
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          toast.success("Map updated successfully", { autoClose: 3000 });
        } else {
          toast.warning("Map update failed", { autoClose: 3000 });
          console.error("Error saving data:", data.message);
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });

    localStorage.setItem('drawingsData', jsonData);

    setDrawingsData(savedData);
  };

  const handleShowAnnouncementModal = () => setShowAnnouncementModal(true);
  const handleCloseAnnouncementModal = () => setShowAnnouncementModal(false);
  const handleShowConfirmationModal = () => {
    setShowAnnouncementModal(false);
    setShowConfirmationModal(true);
  };
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
  const handleConfirmAnnouncement = () => {
    fetch("https://api.intellitraffic.info/php/announce.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        announcementTitle: announcementTitle,
        announcementText: announcementText
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          toast.success("Announcement made successfully", { autoClose: 3000 });
          handleClearAnnouncement();
        } else {
          toast.error("Announcement failed", { autoClose: 3000 });
          console.error("Error making announcement:", data.message);
        }
   
      })
      .catch(error => {
        console.error("Error:", error);
      });

    setShowConfirmationModal(false);
  };
  const handleClearAnnouncement = () => {
    setAnnouncementTitle('');
    setAnnouncementText('');
  };

  const handleAnnouncementList = async () => {
    try {
      const response = await fetch('https://api.intellitraffic.info/php/fetch_announcements.php');
      const data = await response.json();

      if (data.success) {
        setAnnouncements(data.announcements);
        setFilteredAnnouncements(data.announcements);
        setShownnouncementlist(true);
      } else {
        console.error('Error fetching announcements:', data.message);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = announcements.filter((announcement) =>
      announcement.announcement_title.toLowerCase().includes(query) ||
      announcement.announcement_date.toLowerCase().includes(query)
    );
    setFilteredAnnouncements(filtered);
  };

  const handleClose = () => setShownnouncementlist(false);
  const handleDelete = async (announcement) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this announcement?");
    if (confirmDelete) {
      try {
        const response = await fetch('https://api.intellitraffic.info/php/delete_announcement.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: announcement.id }),
        });
        const data = await response.json();
        if (data.success) {
          const updatedAnnouncements = announcements.filter(a => a.id !== announcement.id);
          setAnnouncements(updatedAnnouncements);
          setFilteredAnnouncements(updatedAnnouncements);
          toast.success("Announcement deleted successfully!", { autoClose: 3000 });
        } else {
          console.error('Error deleting announcement:', data.message);
        }
      } catch (error) {
        console.error('Delete error:', error);
      }
    }
  };

  const handleDeleteAll = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete all announcements?");
    if (confirmDelete) {
      try {
        const response = await fetch('https://api.intellitraffic.info/php/delete_announcement.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}),
        });
        const data = await response.json();
        if (data.success) {
          setAnnouncements([]);
          setFilteredAnnouncements([]);
          toast.success("All Announcement deleted successfully!", { autoClose: 3000 });
        } else {
          console.error('Error deleting all announcements:', data.message);
        }
      } catch (error) {
        console.error('Delete all error:', error);
      }
    }
  };

  const handleShowLoadModal = () => setShowLoadModal(true);

  const handleLoad = () => {
    if (!selectedDrawing) {
      toast.warning("Please select a drawing to load", { autoClose: 3000 });
      return;
    }
    fetch("https://api.intellitraffic.info/php/drawload_saved.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ id: selectedDrawing.id })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const mapdrawing = data.mapdrawing;
          setDrawingsData(mapdrawing);
          toast.success("Drawing loaded and updated successfully", { autoClose: 3000 });
          setShowLoadModal(false);
          window.location.reload();
        } else {
          toast.error("Failed to load drawing", { autoClose: 3000 });
          console.error("Error loading drawing:", data.message);
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  const handleSaveload = () => {
    const savedData = overlaysRef.current.map((overlay) => {
      let data = {
        type: overlay.metadata.type,
        position: null,
        name: null,
        size: null,
        color: overlay.metadata.color || null,
      };
      switch (overlay.metadata.type) {
        case google.maps.drawing.OverlayType.MARKER:
          data.position = overlay.getPosition().toJSON();
          data.name = overlay.getLabel();
          break;
        case google.maps.drawing.OverlayType.CIRCLE:
          data.position = overlay.getCenter().toJSON();
          data.size = overlay.getRadius();
          break;
        case google.maps.drawing.OverlayType.POLYGON:
        case google.maps.drawing.OverlayType.POLYLINE:
          data.position = overlay.getPath().getArray().map(point => point.toJSON());
          break;
        case google.maps.drawing.OverlayType.RECTANGLE:
          data.position = overlay.getBounds().toJSON();
          break;
        default:
          console.warn(`Unsupported overlay type: ${overlay.metadata.type}`);
          return null;
      }
      return data;
    }).filter(Boolean);

    const jsonData = JSON.stringify({ drawingsData: savedData, drawingName });
    fetch("https://api.intellitraffic.info/php/draw_save.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: jsonData
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          toast.success("Drawing saved successfully", { autoClose: 3000 });
        } else {
          toast.error("Failed to save drawing", { autoClose: 3000 });
          console.error("Error saving drawing:", data.message);
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };
  const handleDeleteload = () => {
    if (!selectedDrawing) {
      alert('Please select a drawing to delete.');
      return;
    }

    fetch("https://api.intellitraffic.info/php/drawdelete_saved.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ id: selectedDrawing.id })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          toast.success("Drawing deleted successfully", { autoClose: 3000 });
          setSavedDrawings(savedDrawings.filter(drawing => drawing.id !== selectedDrawing.id));
          setSelectedDrawing(null);
        } else {
          toast.error("Failed to delete drawing", { autoClose: 3000 });
          console.error("Error deleting drawing:", data.message);
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="custom-container">
      <ToastContainer closeButton={false} />
      <div className="info-mapdraw">
        <p>
          <span className="highlight">Map </span>Reroute
        </p>
      </div>
      <div className="map-bodydraw">
        <div className="mapdraw">
          <MapComponentDraw
            markerType={markerType}
            drawColor={drawColor}
            eraseMode={eraseMode}
            overlaysRef={overlaysRef}
            initialDrawings={drawingsData}
          />
        </div>
        <div className="info-data">
          <div className="maps-buttondraw">
            <div className="tools-info">
              <h3 className="mapdraw-cat">Marker</h3>
              <Button
                className={`conleveldraw ${activeButton === "entrance" ? "active" : ""}`}
                onClick={() => handleButtonClick("entrance")}
                disabled={activeButton === "entrance"}
              >
                Entrance Only
              </Button>
              <Button
                className={`conleveldraw ${activeButton === "exit" ? "active" : ""}`}
                onClick={() => handleButtonClick("exit")}
                disabled={activeButton === "exit"}
              >
                Exit Only
              </Button>
              <Button
                className={`conleveldraw ${activeButton === "no-entry" ? "active" : ""}`}
                onClick={() => handleButtonClick("no-entry")}
                disabled={activeButton === "no-entry"}
              >
                No Entry
              </Button>
              <h3 className="mapdraw-cat">Construction</h3>
              <Button
                className={`consleveldraw ${activeButton === "drainage" ? "active" : ""}`}
                onClick={() => handleButtonClick("drainage")}
                disabled={activeButton === "drainage"}
              >
                Drainage
              </Button>
              <Button
                className={`consleveldraw ${activeButton === "building" ? "active" : ""}`}
                onClick={() => handleButtonClick("building")}
                disabled={activeButton === "building"}
              >
                Building
              </Button>
              <Button
                className={`consleveldraw ${activeButton === "road" ? "active" : ""}`}
                onClick={() => handleButtonClick("road")}
                disabled={activeButton === "road"}
              >
                Road
              </Button>
              <h3 className="mapdraw-cat">Hazard Levels</h3>
              <Button
                className={`hazardleveldraw ${activeButton === "vehicular-accidents" ? "active" : ""
                  }`}
                onClick={() => handleButtonClick("vehicular-accidents")}
                disabled={activeButton === "vehicular-accidents"}
              >
                Accidents
              </Button>
              <Button
                className={`hazardleveldraw ${activeButton === "fire" ? "active" : ""
                  }`}
                onClick={() => handleButtonClick("fire")}
                disabled={activeButton === "fire"}
              >
                Fire
              </Button>
              <Button
                className={`hazardleveldraw ${activeButton === "flood" ? "active" : ""
                  }`}
                onClick={() => handleButtonClick("flood")}
                disabled={activeButton === "flood"}
              >
                Flood
              </Button>
              <Button
                className={`hazardleveldraw ${activeButton === "landslide" ? "active" : ""
                  }`}
                onClick={() => handleButtonClick("landslide")}
                disabled={activeButton === "landslide"}
              >
                Landslide
              </Button>
              <h3 className="mapdraw-cat">Shapes Colors</h3>
              <Button
                className={`colb colleveldraw ${activeColorButton === "blue" ? "active" : ""
                  }`}
                onClick={() => handleColorButtonClick("blue")}
                disabled={activeColorButton === "blue"}
              >
                Blue
              </Button>
              <Button
                className={`colg colleveldraw ${activeColorButton === "green" ? "active" : ""
                  }`}
                onClick={() => handleColorButtonClick("green")}
                disabled={activeColorButton === "green"}
              >
                Green
              </Button>
              <Button
                className={`colr colleveldraw ${activeColorButton === "red" ? "active" : ""
                  }`}
                onClick={() => handleColorButtonClick("red")}
                disabled={activeColorButton === "red"}
              >
                Red
              </Button>
              <Button
                className={`coly colleveldraw ${activeColorButton === "yellow" ? "active" : ""
                  }`}
                onClick={() => handleColorButtonClick("yellow")}
                disabled={activeColorButton === "yellow"}
              >
                Yellow
              </Button>
              <h3 className="mapdraw-cat">Options</h3>
              <Button className="opleveldraw" onClick={handleSave}>
                Save
              </Button>
              <Button className={`opleveldraw ${eraseMode ? 'erase-on' : ''}`} onClick={handleErase}>
                {eraseMode ? "Erase On" : "Erase"}
              </Button>
              <Button className="opleveldraw" onClick={handleClear}>
                Clear
              </Button>
              <Button className="opleveldraw" onClick={handleShowLoadModal}>Load</Button>
            </div>
            <div className="twobuttonsdraw">
              <Button className="map-buttonsdraw" onClick={handleShowAnnouncementModal}>
                Announcement
              </Button>
              <Button onClick={map} className="map-buttonsdraw">
                Return
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showAnnouncementModal} onHide={handleCloseAnnouncementModal}>
        <Modal.Header closeButton>
          <Modal.Title>Announcement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter the title of your announcement:</p>
          <FormControl
            type="text"
            value={announcementTitle}
            onChange={(e) => setAnnouncementTitle(e.target.value)}
          />
          <p>Enter your announcement:</p>
          <FormControl
            as="textarea"
            rows={3}
            value={announcementText}
            onChange={(e) => setAnnouncementText(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAnnouncementModal}>
            Cancel
          </Button>
          <Button variant="warning" onClick={handleAnnouncementList} >
            Announcements
          </Button>
          <Button variant="danger" onClick={handleClearAnnouncement}>
            Clear
          </Button>
          <Button variant="success" onClick={handleShowConfirmationModal}>
            Announce to Users
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to announce to users?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmationModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmAnnouncement}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAnnouncementlist} onHide={handleClose} dialogClassName="custom-modal-width">
        <Modal.Header closeButton>
          <Modal.Title>Announcements</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <FormControl
            type="text"
            placeholder="Search by Title or Date"
            value={searchQuery}
            onChange={handleSearch}
            className="mb-3"
          />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Title</th>
                <th>Announcement</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredAnnouncements.map((announcement, index) => (
                <tr key={index}>
                  <td className="title-column">{announcement.announcement_title}</td>
                  <td className="text-column">{announcement.announcement}</td>
                  <td className="date-column">{announcement.announcement_date}</td>
                  <td className="delete-column">
                    <Button variant="danger" onClick={() => handleDelete(announcement)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeleteAll}>
            Delete All
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLoadModal} onHide={() => setShowLoadModal(false)} onShow={fetchSavedDrawings}>
        <Modal.Header closeButton>
          <Modal.Title>Load Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl
            type="text"
            placeholder="Enter drawing name"
            value={drawingName}
            onChange={(e) => setDrawingName(e.target.value)}
            className="mb-3"
          />
          <Button variant="success" onClick={handleSaveload} disabled={!drawingName.trim()}>Save Drawing</Button>
          <Button variant="primary" onClick={handleLoad} disabled={!selectedDrawing}>Load Drawing</Button>
          <Button variant="danger" onClick={handleDeleteload} disabled={!selectedDrawing}>Delete Drawing</Button>
          <hr />
          <h5>Saved Drawings</h5>
          <div className="scrollable-list">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Drawing Name</th>
                </tr>
              </thead>
              <tbody>
                {savedDrawings.map((drawing) => (
                  <tr
                    key={drawing.id}
                    onClick={() => setSelectedDrawing(drawing)}
                    className={selectedDrawing && selectedDrawing.id === drawing.id ? 'selected-row' : ''}
                  >
                    <td>{drawing.id}</td>
                    <td>{drawing.drawing_name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLoadModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default MapDrawing;
