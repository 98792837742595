import React from 'react';

function Restricted() {
  const restrictedStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#ff4d4d',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    fontSize: '3em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '5px',
    border: '5px solid #fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    textAlign: 'center'
  };

  const buttonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '1em',
    fontWeight: 'bold',
    color: '#ff4d4d',
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div style={restrictedStyle}>
      Unauthorized Access
      <div>Please go back</div>
      <button style={buttonStyle} onClick={handleGoBack}>
        Go Back
      </button>
    </div>
  );
}

export default Restricted;
