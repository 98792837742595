import React, { useState, useEffect} from "react";
import { Button, Table } from "react-bootstrap";
import "../styles/style.css";
import { useNavigate, useParams } from "react-router-dom";
import axiosphp from "../api/axiosphp";


function DHistory() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { camId } = useParams();

  const gohh = (event) => {
    event.preventDefault();
    navigate(`/hhistory/${camId}`);
  };

  const gohd = (event) => {
    event.preventDefault();
    navigate(`/dhistory/${camId}`);
  };

  const gohw = (event) => {
    event.preventDefault();
    navigate(`/whistory/${camId}`);
  };

  const gohm = (event) => {
    event.preventDefault();
    navigate(`/mhistory/${camId}`);
  };

  const gomap = (event) => {
    event.preventDefault();
    navigate(`/map`);
  };

  const golinterface = (event) => {
    event.preventDefault();
    navigate(`/liveinterface/${camId}`);
  };

  useEffect(() => {
    axiosphp.get(`/fetch_traffic_history.php?cam_id=${camId}`)
      .then(response => {
        setData(response.data.daily);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  function formatDuration(totalSeconds) {
    totalSeconds = Math.round(totalSeconds);
    let durationDisplay = `${totalSeconds} second${totalSeconds !== 1 ? 's' : ''}`;

    if (totalSeconds >= 60) {
      const minutes = Math.floor(totalSeconds / 60);
      const remainingSeconds = totalSeconds % 60;
      durationDisplay = `${minutes} minute${minutes !== 1 ? 's' : ''} ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;
    }

    if (totalSeconds >= 3600) {
      const hours = Math.floor(totalSeconds / 3600);
      const remainingMinutes = Math.floor((totalSeconds % 3600) / 60);
      const remainingSeconds = totalSeconds % 60;
      durationDisplay = `${hours} hour${hours !== 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''} ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;
    }

    return durationDisplay;
  }

  return (
    <div className="custom-container">
      <div className="info-h">
        <p><span className="highlight">DAILY </span>TRAFFIC HISTORY</p>
      </div>
      <div className="table-and-buttons">
        <Table className="table-d" responsive>
          <thead className="theader">
            <tr>
              <th>ID</th>
              <th>Camera Location</th>
              <th>Date/Time</th>
              <th>Vehicle Count</th>
              <th>Daily Congestion</th>
              <th>Congestion Time</th>
            </tr>
          </thead>
          <tbody className="tablebody">
            {data.map((row, index) => (
              <tr key={index}>
                <td>{row.d_id}</td>
                <td>{row.cam_location}</td>
                <td>{row.date_time}</td>
                <td>{row.total_vehicle}</td>
                <td>{row.daily_congestion}</td>
                <td>{formatDuration(row.congestion_time)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="info-data">
          <div className="his-button">
            <Button onClick={gohh} className="all-button h-button">
              HOURLY
            </Button>
            <Button onClick={gohd} className="all-button current-btn h-button">
              DAILY
            </Button>
            <Button onClick={gohw} className="all-button h-button">
              WEEKLY
            </Button>
            <Button onClick={gohm} className="all-button h-button">
              MONTHLY
            </Button>
            <Button onClick={golinterface} className="all-button">
              LIVE
            </Button>
            <Button onClick={gomap} className="all-button">
              MAP
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DHistory;
