// src/pages/login.js
import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import "../styles/style.css";
import axiosphp from "../api/axiosphp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../context/UserContext";

function Login() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext); 
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  

  useEffect(() => {
    if (user) {
      if (user.role === 'admin') {
        navigate("/commandc");
      } else if (user.role === 'user') {
        navigate("/map");
      }
    }
  }, [user, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosphp.post("/login.php", {
        username: username,
        password: password,
      });
      console.log(response.data);
      if (response.data.success) {
        setUser({ username: username, role: response.data.role }); // Set user information in context
        if (response.data.role === 'admin') {
          navigate("/commandc");
        } if (response.data.role === 'user') {
          navigate("/map");
        }
      } else {
        toast.error("Login failed: Invalid username or password");
      }
    } catch (error) {
      console.error("An error occurred while logging in:", error);
    }
  };

  return (
    <section className="custom-container bg-login">
      <ToastContainer  closeButton={false}/>
      <div className="row justify-content-center custom-login">
        <div className="col-md-6 custom-login-place">
          <h1 className="tlogin">IntelliTraffic</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="username"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Login
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
export default Login;