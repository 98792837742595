// Map.js
import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MapComponent from "../components/MapComponent";
import "../styles/style.css";
import { UserContext } from "../context/UserContext"; 
function MapPage() {
  const navigate = useNavigate();
  const [drawingsData, setDrawingsData] = useState([]); 
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    fetch("https://api.intellitraffic.info/php/drawfetch.php")
      .then(response => response.json())
      .then(data => {
        if (data.success && data.drawingsData) {
          setDrawingsData(data.drawingsData);
        } else {
          console.error("Error fetching data:", data.message);
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  }, []);

  const golinterface = (event) => {
    event.preventDefault();
    navigate("/mapdraw");
  };

  const goforecast = (event) => {
    event.preventDefault();
    navigate("/mapforecast");
  };

  const logout = (event) => {
    setUser(null);
    event.preventDefault();
    navigate("/login");
  };

  return (
    <div className="custom-container">
      <div className="info-map">
        <p>
          <span className="highlight">Map </span>System
        </p>
      </div>
      <div className="map-body">
        <div className="map">
          <MapComponent initialDrawings={drawingsData} />
        </div>
        <div className="info-data">
          <div className="maps-button">
            <div className="congestion-info">
              <p className="conlevel">
                GRIDLOCK <span className="circle gridlock"></span>
              </p>
              <p className="conlevel">
                HEAVY <span className="circle heavy"></span>
              </p>
              <p className="conlevel">
                MODERATE <span className="circle moderate"></span>
              </p>
              <p className="conlevel">
                LOW <span className="circle low"></span>
              </p>
              <p className="conlevel">
                FREE FLOW <span className="circle free-flow"></span>
              </p>
            </div>
            <div className="twobuttons">
              <Button onClick={goforecast} className="map-buttons">
                Map Forecast
              </Button>
              <Button onClick={golinterface} className="map-buttons">
                Map Reroute
              </Button>
              <Button onClick={logout} className="map-buttons">
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MapPage;