import React, { useState, useContext, useRef, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../styles/style.css";
import nosig from "../images/nosig.jpg";
import { UserContext } from "../context/UserContext";
import useCameraData from "../hooks/useCameraData";
import axios from "../api/axios";
import axiosphp from "../api/axiosphp";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { io } from "socket.io-client";


function LiveInterface() {
  const navigate = useNavigate();
  const { camId } = useParams();
  const { cameraData, loading, error } = useCameraData(camId);
  const { setUser } = useContext(UserContext);
  const location = useLocation();
  const camera = location.state?.camera;
  const [lanePolygon, setLanePolygon] = useState(
    camera?.lane_position ? JSON.parse(camera.lane_position) : []
  );

  const [draggingIndex, setDraggingIndex] = useState(null);
  const [imageSrc, setImageSrc] = useState('');
  const socketRef = useRef(null);

  useEffect(() => {
    const socket = io('wss://api.intellitraffic.info/video', {
      transports: ['websocket'],
    });
  
    socket.on('connect', () => {
      console.log('Connected to Socket.IO server');
      socket.emit('join_room', { cam_id: camId });
    });
  
    socket.on('video_frame', (data) => {
      if (data.cam_id === parseInt(camId)) {
        const blob = new Blob([data.frame], { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob);
        setImageSrc(url);
      }
    });
  
    socketRef.current = socket;
  
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [camId]);

  const handleMouseDown = (index) => {
    setDraggingIndex(index);
  };

  const handleMouseMove = (e) => {
    if (draggingIndex !== null) {
      const newPolygon = [...lanePolygon];
      newPolygon[draggingIndex] = { x: e.clientX, y: e.clientY };
      setLanePolygon(newPolygon);
    }
  };

  const handleMouseUp = () => {
    setDraggingIndex(null);
  };

  const saveLane = () => {
    const data = {
      cam_id: camId,
      lane_position: lanePolygon,
    };

    axiosphp
      .post("/update_lane_position.php", data)
      .then((response) => {
        if (response.data.success) {
          console.log("Lane position updated in the database.");
          return axios.post(`/save_lane_polygon/${camId}`, {
            polygon: lanePolygon,
          });
        } else {
          console.error("Error updating lane position:", response.data.message);
          throw new Error(response.data.message);
        }
      })
      .then((response) => {
        console.log("Polygon updated in backend:", response.data);
        toast.success("Lane updated successfully!");
      })
      .catch((error) => {
        // console.error("Error saving polygon:", error);
        // toast.error("Error updating lane: " + error.message);
      });
  };

  const gomap = (event) => {
    event.preventDefault();
    navigate("/map");
  };

  const gohistory = (event) => {
    event.preventDefault();
    navigate(`/hhistory/${camId}`);
  };

  const logout = (event) => {
    setUser(null);
    event.preventDefault();
    navigate("/login");
  };

  const tcongestionColor = {
    Gridlock: "#AB0003",
    Heavy: "#FF1519",
    Moderate: "#FFA800",
    Light: "#FFE500",
    Freeflow: "#05FF00",
  };

  // Function to format duration from seconds to string
  function formatDuration(totalSeconds) {
    totalSeconds = Math.floor(totalSeconds);
    if (totalSeconds >= 3600) {
      const hours = Math.floor(totalSeconds / 3600);
      const remainingMinutes = Math.floor((totalSeconds % 3600) / 60);
      const remainingSeconds = totalSeconds % 60;
      return `${hours} hours ${remainingMinutes} minutes ${remainingSeconds} seconds`;
    } else if (totalSeconds >= 60) {
      const minutes = Math.floor(totalSeconds / 60);
      const remainingSeconds = totalSeconds % 60;
      return `${minutes} minutes ${remainingSeconds} seconds`;
    } else {
      return `${totalSeconds} seconds`;
    }
  }

  // Access duration directly since there's only one lane
  const duration = cameraData?.durations || 0;
  const durationDisplay = formatDuration(duration);

  const handleImageError = (e) => {
    e.target.src = nosig;
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  if (error) return <div>Error: {error}</div>;



  return (
    <div
      className="custom-container"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <ToastContainer closeButton={false} />
      {/* Camera Information */}
      <div className="caminfo">
        <p>
          Camera:{" "}
          <span className="highlight">{cameraData?.cam_id || "No Data"}</span>
        </p>
        <p>
          Street/Unit:
          <span className="highlight">
            {" "}
            {cameraData?.cam_location || "No Data"}
          </span>
        </p>
        <p>
          Name:
          <span className="highlight">{cameraData?.cam_city || "No Data"}</span>
        </p>
      </div>

      {/* Video Frame */}
      {imageSrc ? (
        <img
          className="video-frame"
          src={imageSrc}
          alt="Video Frame"
          onError={handleImageError}
        />
      ) : (
        <Spinner animation="border" />
      )}

      {/* SVG overlay for lane editing */}
      <svg

      >
        <polygon
          points={lanePolygon.map((point) => `${point.x},${point.y}`).join(" ")}
          fill="none"
          stroke="white"
          strokeWidth="2"
        />
        {/* Draggable Points */}
        {lanePolygon.map((point, index) => (
          <circle
            key={index}
            cx={point.x}
            cy={point.y}
            r={5}
            fill="blue"
            onMouseDown={() => handleMouseDown(index)}
          />
        ))}
      </svg>

      {/* Traffic Information */}
      <div className="trafficinfo">
        {/* Congestion Level */}
        <div className="tinfo">
          <h4>Congestion Level</h4>
          <div className="tcinfo1">
            <p
              style={{
                color: tcongestionColor[cameraData?.tcongestion] || "black",
              }}
            >
              {cameraData?.tcongestion || "Open Road"}
            </p>
          </div>
        </div>

        {/* Vehicle Types */}
        <div className="tinfo">
          <h4>Vehicle Type</h4>
          <div className="tcinfo2">
            <p>Car: {cameraData?.class_counts?.Car || 0}</p>
            <p>Bus: {cameraData?.class_counts?.Bus || 0}</p>
            <p>Jeep: {cameraData?.class_counts?.Jeep || 0}</p>
            <p>Motorbike: {cameraData?.class_counts?.Motorbike || 0}</p>
            <p>Tricycle: {cameraData?.class_counts?.Tricycle || 0}</p>
            <p>Truck: {cameraData?.class_counts?.Truck || 0}</p>
            <p>Van: {cameraData?.class_counts?.Van || 0}</p>
          </div>
        </div>

        {/* Vehicle Counter */}
        <div className="tinfo">
          <h4>Vehicle Counter</h4>
          <div className="tcinfo1">
            <p>{cameraData?.object_counts || 0}</p>
          </div>
        </div>

        {/* Traffic Duration */}
        <div className="tinfo">
          <h4>Traffic Duration</h4>
          <div className="tcinfo1">
            <p>{durationDisplay}</p>
          </div>
        </div>

        {/* Total Vehicle Count */}
        <div className="tinfo">
          <h4>Total Vehicle Count</h4>
          <div className="tcinfo1">
            <p>{cameraData?.zone_counters || "None"}</p>
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div
        className="buttonstwo"
        style={{ position: "relative", zIndex: 3 }}
      >
        <Button onClick={logout}>Log Out</Button>
        <Button onClick={gohistory}>Traffic History</Button>
        <Button onClick={gomap}>Map</Button>
        <Button onClick={saveLane} className="save-lane-button">
          Save Lane
        </Button>
      </div>
    </div>
  );
}

export default LiveInterface;
