import React, { useState, useEffect, useContext} from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import "../styles/style.css";
import { useNavigate } from "react-router-dom";
import axiosphp from "../api/axiosphp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../context/UserContext";

function CommandTenforcer() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { setUser } = useContext(UserContext);

  const [newUser, setNewUser] = useState({
    firstname: "",
    lastname: "",
    username: "",
    password: ""
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const goc = (event) => {
    event.preventDefault();
    navigate("/commandc");
  };

  const gote = (event) => {
    event.preventDefault();
    navigate("/commandte");
  };
  const gota = (event) => {
    event.preventDefault();
    navigate("/commandta");
  };

  const goa = (event) => {
    event.preventDefault();
    navigate("/commanda");
  };
  const goh = (event) => {
    event.preventDefault();
    navigate("/commandh");
  };

  const gomc = (event) => {
    event.preventDefault();
    navigate("/commandmc");
  };

  const logout = (event) => {
    setUser(null);
    event.preventDefault();
    navigate("/login");
  };

  useEffect(() => {
    axiosphp.get('/fetch_accounts_data.php')
      .then(response => {
        setData(response.data.enforcer); 
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);
  const handleInputChange = (event) => {
    setNewUser({
      ...newUser,
      [event.target.name]: event.target.value
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const newUserWithType = { ...newUser, type: 'traffic_enforcer' };
    axiosphp.post('/account_insert.php', newUserWithType)
      .then(response => {
        if (response.data.success) {
          setData([...data, response.data.user]);
          setShowModal(false);
          setNewUser({ firstname: "", lastname: "", username: "", password: "" });
          toast.success("User inserted successfully!", { autoClose: 3000 });
        } else {
          console.error('There was an error!', response.data.message);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  const handleRowClick = (user, index) => {
    setSelectedUser(user);
    setSelectedRowIndex(index);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const updatedUser = selectedUser; 
    axiosphp.put(`/account_update.php?id=${selectedUser.id}`, updatedUser)
      .then(response => {
        if (response.data.success) {
          setData(data.map(user => user.id === selectedUser.id ? response.data.user : user)); 
          setSelectedUser(null);
          setShowEditModal(false); 
          toast.success("User updated successfully!", { autoClose: 3000 });
        } else {
          console.error('There was an error!', response.data.message);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  const handleEditButtonClick = () => {
    if (selectedUser) {
      setShowEditModal(true);
    } else {
      toast.info("Please select user to EDIT", { autoClose: 3000 });
    }
  };

  const handleDeleteButtonClick = () => {
    if (selectedUser) {
      setShowDeleteModal(true);
    } else {
      toast.info("Please select user to DELETE", { autoClose: 3000 });
    }
  };

  const handleDeleteConfirm = () => {
    const type = 'traffic_enforcer'; 
    axiosphp.delete(`/account_delete.php?id=${selectedUser.id}&type=${type}`)
      .then(response => {
        if (response.data.success) {
          setData(data.filter(user => user.id !== selectedUser.id)); 
          setSelectedUser(null); 
          setShowDeleteModal(false);
          toast.success("User deleted successfully!", { autoClose: 3000 });
        } else {
          console.error('There was an error!', response.data.message);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  return (
    <div className="custom-container">
      <ToastContainer  closeButton={false}/>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" name="firstname" value={newUser.firstname} onChange={handleInputChange} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" name="lastname" value={newUser.lastname} onChange={handleInputChange} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" name="username" value={newUser.username} onChange={handleInputChange} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" value={newUser.password} onChange={handleInputChange} required />
            </Form.Group>
            <Button type="submit">Add Account</Button>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>


      <Modal show={showEditModal} onHide={() => { setShowEditModal(false); setSelectedUser(null); }}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditFormSubmit}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" name="firstname" value={selectedUser ? selectedUser.te_firstname : ''} onChange={(e) => setSelectedUser({ ...selectedUser, te_firstname: e.target.value })} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" name="lastname" value={selectedUser ? selectedUser.te_lastname : ''} onChange={(e) => setSelectedUser({ ...selectedUser, te_lastname: e.target.value })} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" name="username" value={selectedUser ? selectedUser.te_username : ''} onChange={(e) => setSelectedUser({ ...selectedUser, te_username: e.target.value })} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" value={selectedUser ? selectedUser.te_password : ''} onChange={(e) => setSelectedUser({ ...selectedUser, te_password: e.target.value })} required />
            </Form.Group>
            <Button type="submit">Edit Account</Button>
            <Button variant="secondary" onClick={() => { setSelectedUser(null); setShowEditModal(false); }}>Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this account?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeleteConfirm}>Delete Account</Button>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <div className="info-hc">
        <p><span className="highlight">Enforcer </span> Account Management</p>
      </div>
      <div className="table-and-buttons">
        <Table className="table-dc" responsive>
          <thead className="theaderc">
            <tr>
              <th>ID</th>
              <th>Firstname</th>
              <th>Lastname</th>
              <th>Username</th>
              <th>Password</th>
            </tr>
          </thead>
          <tbody className="tablebodyc">
            {data.map((row, index) => (
              <tr key={index} onClick={() => handleRowClick(row, index)} className={index === selectedRowIndex ? 'selected' : ''}>
                <td>{row.id}</td>
                <td>{row.te_firstname}</td>
                <td>{row.te_lastname}</td>
                <td>{row.te_username}</td>
                <td>{'****'}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="6">
                <div className="func-container">
                  <Button className="func-button" onClick={() => setShowModal(true)}>
                    Add
                  </Button>
                  <Button className="func-button" onClick={handleEditButtonClick}>
                    Edit
                  </Button>
                  <Button className="func-button" onClick={handleDeleteButtonClick}>
                    Delete
                  </Button>
                  <Button onClick={logout} className="current-func">
                    Logout
                  </Button>
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>

        <div className="info-datac">
          <div className="his-button">
            <Button onClick={goc} className="all-buttonc  h-buttonc">
              Civilian
            </Button>
            <Button onClick={gote} className="all-buttonc current-btnc h-buttonc">
              Traffic Enforcer
            </Button>
            <Button onClick={gota} className="all-buttonc h-buttonc">
              Traffic Agency
            </Button>
            <Button onClick={goa} className="all-buttonc h-buttonc">
              Admin
            </Button>
            <Button onClick={goh} className="all-buttonc  h-buttonc">
              Traffic History
            </Button>
            <Button onClick={gomc} className="all-buttonc   h-buttonc">
              Multiple Camera
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CommandTenforcer;
