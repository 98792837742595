import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet
import axiosphp from './api/axiosphp'; // Import axios
import { UserProvider } from './context/UserContext'; // Import UserProvider
import LiveInterface from './pages/liveinterface';
import LiveInterfaceTwo from './pages/liveinterfacetwo';
import Login from './pages/login';
import Hhistory from './pages/hHistory';
import Dhistory from './pages/dHistory';
import Whistory from './pages/wHistory';
import Mhistory from './pages/mHistory';
import Map from './pages/map';
import MapForecast from './pages/mapForecast';
import MapDrawing from './pages/mapdrawing';
import Commandc from './pages/commandCivillian';
import Commandte from './pages/commandTenforcer';
import Commandta from './pages/commandTagency';
import Commanda from './pages/commandAdmin';
import Commandh from './pages/commandThistory';
import CommandMc from './pages/commandMcamera';
import Restricted from './pages/restricted';
import PrivateRoute from './context/PrivateRoute'; // Import PrivateRoute

function App() {
  const [cameraData, setCameraData] = useState([]);
  const [cameraStatus, setCameraStatus] = useState({});
  const [pollingInterval, setPollingInterval] = useState(null);

  const handleImageError = (event) => {
    console.error("Error fetching video frame:", event);
  };

  const fetchCameraData = () => {
    axiosphp.get("/fetch_traffic_cameras.php")
      .then(response => {
        setCameraData(response.data);
      })
      .catch(error => {
        console.error("Error fetching camera data:", error);
      });
  };

  // Utility function to check rate limit
  const canCheckVideoFrame = (camId) => {
    const lastCheck = localStorage.getItem(`checkVideoFrame_${camId}`);
    const now = Date.now();
    if (lastCheck && now - lastCheck < 15000) { // can be change to 10000 10 seconds
      console.log(`checkVideoFrame for camId ${camId} is on cooldown.`);
      return false;
    }
    // Update the last check timestamp
    localStorage.setItem(`checkVideoFrame_${camId}`, now);
    return true;
  };

  const checkVideoFrame = (camId) => {
    if (!camId) return;

    // Check if the function can proceed
    if (!canCheckVideoFrame(camId)) {
      return;
    }
    // need to setup proxy to be able to see the videos continuesly in the package.json file
    //change it to http://localhost:5000/video_frame/${camId}
    const img = new Image();
    img.src = `https://api.intellitraffic.info/flask/video_frame/${camId}`;
    img.alt = "Video Frame";
    img.style.display = "none"; // Hide the image
    img.onerror = handleImageError;
    img.onload = () => {
      setCameraStatus((prevStatus) => ({
        ...prevStatus,
        [camId]: false,
      }));
    };
    document.body.appendChild(img);

    // Clean up the image element after it has been added to the DOM
    setTimeout(() => {
      document.body.removeChild(img);
    }, 5000); // Remove the image after 5 seconds
  };

  const staggeredCheck = () => {
    if (Array.isArray(cameraData)) {
      cameraData.forEach((cam, index) => {
        setTimeout(() => checkVideoFrame(cam.cam_id), index * 1000); // Stagger requests by 1 second
      });
    } else {
      // console.error("cameraData is not an array:", cameraData);
    }
  };

  const startPolling = () => {
    // Start polling for camera data every 5 seconds
    if (!pollingInterval) {
      const intervalId = setInterval(fetchCameraData, 10000); // can be changed to 5000 to  Poll every 5 seconds
      setPollingInterval(intervalId);
    }
  };

  const stopPolling = () => {
    // Clear the polling interval if it's set
    if (pollingInterval) {
      clearInterval(pollingInterval);
      setPollingInterval(null);
    }
  };

  useEffect(() => {
    fetchCameraData(); // Fetch camera data when the component mounts
    startPolling(); // Start polling

    return () => stopPolling(); // Clean up interval on unmount
  }, []);

  useEffect(() => {
    if (cameraData.length > 0) {
      staggeredCheck(); // Start the check right after fetching the camera data
    }
  }, [cameraData]); // Only run when `cameraData` changes

  return (
    <UserProvider>
      <Helmet>
        <title>IntelliTraffic</title>
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/restricted" element={<Restricted />} />

          <Route path="/liveinterface/:camId" element={<PrivateRoute element={LiveInterface} allowedRoles={['user']} />} />
          <Route path="/liveinterfacetwo/:camId" element={<PrivateRoute element={LiveInterfaceTwo} allowedRoles={['user']} />} />
          <Route path="/hhistory/:camId" element={<PrivateRoute element={Hhistory} allowedRoles={['user']} />} />
          <Route path="/dhistory/:camId" element={<PrivateRoute element={Dhistory} allowedRoles={['user']} />} />
          <Route path="/whistory/:camId" element={<PrivateRoute element={Whistory} allowedRoles={['user']} />} />
          <Route path="/mhistory/:camId" element={<PrivateRoute element={Mhistory} allowedRoles={['user']} />} />
          <Route path="/map" element={<PrivateRoute element={Map} allowedRoles={['user']} />} />
          <Route path="/mapforecast" element={<PrivateRoute element={MapForecast} allowedRoles={['user']} />} />
          <Route path="/mapdraw" element={<PrivateRoute element={MapDrawing} allowedRoles={['user']} />} />

          <Route path="/commandc" element={<PrivateRoute element={Commandc} allowedRoles={['admin']} />} />
          <Route path="/commandte" element={<PrivateRoute element={Commandte} allowedRoles={['admin']} />} />
          <Route path="/commandta" element={<PrivateRoute element={Commandta} allowedRoles={['admin']} />} />
          <Route path="/commanda" element={<PrivateRoute element={Commanda} allowedRoles={['admin']} />} />
          <Route path="/commandh" element={<PrivateRoute element={Commandh} allowedRoles={['admin']} />} />
          <Route path="/commandmc" element={<PrivateRoute element={CommandMc} allowedRoles={['admin']} />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
