import { useEffect, useState} from "react";
import axios from "../api/axios";
export const useFetchCongestionData = (cameraData, updateMarkerIcon) => {
  const [intervals, setIntervals] = useState({});
  const fetchCongestionLevel = async (cam_id) => {
    try {
      const response = await axios.get(`/backend_data/${cam_id}`);
      return response.data.tcongestion || null;
    } catch (error) {
      if (error.response?.status === 404) {
        console.warn(`Camera ${cam_id} not found. Stopping fetch.`);
        clearIntervalForCamera(cam_id); // Stop the interval if 404
      }
      console.error(`Error fetching congestion data for camera ${cam_id}:`, error);
      return null;
    }
  };
  const clearIntervalForCamera = (cam_id) => {
    if (intervals[cam_id]) {
      clearInterval(intervals[cam_id]); 
      setIntervals((prev) => {
        const updated = { ...prev };
        delete updated[cam_id]; 
        return updated;
      });
    }
  };
  const continuouslyFetchCongestionData = (camera, marker) => {
    if (!camera || !camera.cam_id) return;
    clearIntervalForCamera(camera.cam_id); 
    const intervalId = setInterval(async () => {
      const isCameraStillPresent = cameraData.some(
        (cam) => cam.cam_id === camera.cam_id
      );
      if (!isCameraStillPresent) {
        clearIntervalForCamera(camera.cam_id); 
        return;
      }
      try {
        const tcongestion = await fetchCongestionLevel(camera.cam_id);
        if (tcongestion !== null) {
          updateMarkerIcon(marker, tcongestion, camera);
        }
      } catch (error) {
        console.error(`Error fetching congestion data for camera ${camera.cam_id}:`, error);
        clearIntervalForCamera(camera.cam_id); 
      }
    }, 3000);
    setIntervals((prev) => ({ ...prev, [camera.cam_id]: intervalId }));
  };
  useEffect(() => {
    return () => {
      Object.values(intervals).forEach(clearInterval);
    };
  }, [intervals]);

  return { continuouslyFetchCongestionData, clearIntervalForCamera };
};