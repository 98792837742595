import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from './UserContext';
const PrivateRoute = ({ element: Component, allowedRoles, ...rest }) => {
  const { user, loading } = useContext(UserContext);
  if (loading) {
    return <div>Loading Please Wait...</div>;
  }
  if (!user || !allowedRoles.includes(user.role)) {
    return <Navigate to="/restricted" />;
  }
  return <Component {...rest} />;
};
export default PrivateRoute;
